import { render, staticRenderFns } from "./inventory.vue?vue&type=template&id=5e96c32a&scoped=true"
import script from "./inventory.vue?vue&type=script&lang=js"
export * from "./inventory.vue?vue&type=script&lang=js"
import style0 from "./inventory.vue?vue&type=style&index=0&id=5e96c32a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e96c32a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
